import React from "react"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelp"
import Reviews from "../components/Home/Reviews/Reviews"
import InstructMG from '../components/Static/instruct-mg/instruct-mg'
import FeaturedProperties from "../components/PropertyDetails/Similar/featured-properties"
import Started from "../components/Home/started/started"
import EastSchedule from '../components/Static/EastSchedule/EastSchedule'
import OfficeNumbers from '../components/Static/OfficeNumbers/office-details-stats'
import Team from '../components/OurOffices/office-details-team'
import Map from '../components/map/office-details-map'
import SEO from "../components/seo"



const OfficeDetail = (props) => {
    const data = props.data.strapiOffices
	//console.log("props => ", props)
	var meta_desc = "You’re welcome to get in touch with your local "+data.Name+" estate agents at Martyn Gerrard. Start benefiting from a friendly, personalised property service."

	return (
		<div className="office-details-page">
	      <Helmet
	          bodyAttributes={{
	              class: 'office-details-page'
	          }}
	      />
	        <HeaderTwo />
	        <SEO location={props.location} title={`Estate Agents in ${data ? data.Meta_Title : data.Name}`} description={meta_desc} />
	        <div className="content">
	        <EastSchedule data={data}/>

	        {data.Statistics ?
	        <OfficeNumbers data={data.Statistics} intro={data.Statistics_Intro}/> : '' }

	        {data.Latitude ?
	        <Map lat={data.Latitude} log={data.Longtitude} Title={data.Name}/> : '' }

	        <Reviews />
	        {data.Instruct_MG ?
	        <InstructMG Reasons={data.Instruct_MG.Reasons} Content={data.Instruct_MG.Content}/> : '' }
	        <Started />
	        <Team url={data.URL} Title={data.Name} intro={data.Team_Members_Section_Intro}/>
	        {data.Name !== 'Property Management' && <FeaturedProperties place={data.Name} page="office"/>}
	        <CanHelp />
	        </div>
	        <Footer guide={data.area_guide} Select_Popular_Search="Static_Contact" popularSearch="static" area={data.URL} page="office"/>
		</div>
	)
}

export default OfficeDetail

export const pageQuery = graphql`query OfficeQuery($slug: String!) {
  strapiOffices(URL: {eq: $slug}) {
    Address
    Email
    Latitude
    Longtitude
    Meta_Description
    Meta_Title
    Name
    URL
    Opening_Hours
    Phone
    Instruct_MG {
      Content
      Reasons {
        Content
        Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 120, layout: CONSTRAINED)
          }
        }
        Title
      }
    }
    Statistics_Intro
    Statistics {
      Add_Stats {
        Content
        Count
      }
    }
    Peoples_to_Contact {
      Tile_Image {
        publicURL
      }
      URL
      Name
      Phone
      Email
      Designation
    }
    Office_Images_Videos {
      Embed_Video_URL
      Title
      Upload_Video {
        publicURL
      }
      Image {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 750, layout: CONSTRAINED)
        }
      }
    }
    area_guide {
      URL
      Title
    }
    Team_Members_Section_Intro
    Usearea_General
    Usearea_Lettings
    Usearea_Sales
  }
}
`
